@font-face {
    font-family: 'ChakraPetchRegular';
    src: url("/fonts/ChakraPetch-Regular.ttf") format('truetype');    
  }
html { font-family: 'ChakraPetchRegular'; letter-spacing: 1px;}


html {
    background-color: #05113b;
}

div {
    border-radius: 2px;
}

.button, .connect, .trade {
    cursor: pointer; 
    transition: 0.5s all;

    &:hover {
        transform: scale(1.05);
    }
}

#checkout .relative.mx-auto.max-w-sm.w-full.text-sm {
    color: green;
}

.launch-app-btn {
    border-radius: 4px;
    background: linear-gradient(to left top, #8914d0 0%/*bottom-right color*/, rgba(255, 0, 255, 0.5) 40% /*middle color*/, #02c9fe 100% /*top-left color*/);
    padding: 10px 22px;
    color: #fff;
    outline: none;  
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin-left: 24px;
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    width: 144px;
    height: 24px;
}

.section-1-glow {
    position: absolute;
    top: 0px;
    left: 0px;
}

.section-1-partners-overlay {
    position: absolute;                    
    top: 0px; 
    left: 0px;
    z-index: 4;
}

.section-1-partners-overlay-left {
    position: absolute;                    
    top: 0px; 
    right: 0px;
    z-index: 1;
}

.terms-popup {
    .btns-wrapper {
        display: flex;
        justify-content: center;
        gap: 15px;

        .accept {
            background: linear-gradient(to left top, #8914d0 0%/*bottom-right color*/, rgba(255, 0, 255, 0.5) 40% /*middle color*/, #02c9fe 100% /*top-left color*/);
            padding: 10px 22px;
            color: #fff;
            outline: none;  
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            text-decoration: none;
            text-align: center;
            line-height: 24px;
            font-size: 16px;
            width: 60px;
            height: 24px;
        }

        .decline {
            color: white;
            background: #05113B;
            padding: 10px;
            cursor: pointer;
            width: 84px;
            text-align: center;
        }
    }            
}

body {
    font-family: 'ChakraPetchRegular';        

    nav {
        a {
            z-index: 9;
        }
        .nav-dropdown {
            position: relative;

            .dropdown-caret {
                transform: rotate(180deg);
                margin-left: 5px;
                margin-top: 2px;  
                transition: 0.3s all;              
            }            

            &:hover {
                .nav-dropdown-wrapper {
                    display: block;
                }

                .dropdown-caret {
                    transform: rotate(0);
                }
            }
            .nav-dropdown-wrapper {
                display: none;
                position: absolute;
                top: 40px;
                left: -35px;
                background-color: #0d194e;
                padding: 0 8px;
                transition: 0.3s all;

                a {
                    padding-left: 12px;
                    padding-right: 12px;

                    &:nth-child(2) {
                        padding-top: 24px;
                        padding-bottom: 12px;
                        border-bottom: 1px solid #142970;
                    }

                    &:last-child {
                        padding-top: 12px;
                        padding-bottom: 24px;
                    }

                    img {
                        margin-right: 8px;
                    }
                }

                .caret {
                    position: relative;
                  }
                  
                  .caret:before {
                    content: '';
                    position: absolute;
                    top: -60px;
                    padding-top: 50px;
                    left: 40%;
                    border-top: 10px solid #0d194e;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    transform: rotate(180deg);
                  }
                  
                  .caret:after {
                    content: '';
                    position: absolute;
                    left: 100px;
                    top: 0;
                    border-top: -10px solid #fff;
                    border-left: -10px solid transparent;
                    border-right: -10px solid transparent;
                  }
            }
        }
    }


    .landing-container {        
        position: relative;
        display: flex;
        flex-direction: column;
        color: #fff;  
        padding: 0.2rem calc((100vw - 1300px) / 2);
        padding-top: 85px;
        height: 660px;
        padding-bottom: 50px;
        overflow: hidden;

        /* SECTION 1 START */        

        &.terms-wrapper {

            .ReactModal__Overlay {
                background-color: rgba(54, 54, 54, 0.233);
              }

            height: unset;

            .title {
                font-size: 48px;
                margin-bottom: 30px;
                font-weight: bold;
                text-align: center;
                cursor: default;
            }

            .paragraph {                
                .p-title {
                    font-size: 26px;
                }
            }
        }

        .section-1-wrapper {
            z-index: 5;
            .section-1-title {
                width: 540px;
                font-weight: bold;
                font-size: 54px;
                line-height: 68px;            
            }
            
            .section-1-subtitle {
                margin-top: 20px;
                width: 540px;
                font-size: 20px;
                line-height: 32px;
            }
    
            .section-1-buttons {
                display: flex;
                margin-top: 48px;
                gap: 24px;
                z-index: 4;
                width: fit-content;
    
                .connect, .trade {
                    font-size: 20px;
                    line-height: 36px;
                    text-align: center;
                    height: 36px;
                    padding: 16px 48px;     
                    cursor: pointer;           
                }
    
                .connect {
                    background: linear-gradient(to left top, #8914d0 0%/*bottom-right color*/, rgba(255, 0, 255, 0.5) 40% /*middle color*/, #02c9fe 100% /*top-left color*/);                
                    color: #fff;
                    width: 186px;                          
                }
    
                .trade {
                    position: relative;
                    width: 122px;                
                    background-color: #05113b;
                    border: 3px solid transparent;
                    height: 25px;
                    line-height: 25px;
                    margin-top: 3px;
    
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0; right: 0; bottom: 0; left: 0;
                        z-index: -1;
                        margin: -6px; /* !importanté */
                        border-radius: inherit; /* !importanté */
                        background: linear-gradient(to left top, #8914d0 0%/*bottom-right color*/, rgba(255, 0, 255, 0.5) 40% /*middle color*/, #02c9fe 100% /*top-left color*/);
                      }
                }
            }
        }
                    

        .section-1-background {
            position: absolute;
            z-index: 2;
            right: -440px;
        }  

        .section-1-background-glow {
            background: linear-gradient(180deg, rgba(5, 17, 59, 0) 0%, #7b25ca 158.71%);
            position: absolute;
            z-index: 3;
            width: 724px;
            height: 18px;
            bottom: 65px;                
            right: 0px;             
            border-radius: 20px;
        }
        
        .section-1-moon {
            position: absolute;
            z-index: 1;
            right: 0px;
            bottom: 180px;
            z-index: 1;            
        } 
        
        /* SECTION 1 END */

        /* SECTION 1-2 START */    

        &.section-1-2 {
            background-color: #0C1A4A;
            margin: 0;

            &.bg-deepcove {
                background-color: #05113b;
            }

            .title {
                font-size: 48px;
                margin-bottom: -30px;
                font-weight: bold;
                text-align: center;
                cursor: default;
            }

            .section-3-lower {
                display: flex;
                justify-content: center;
                gap: 32px;
                margin-top: 126px;
                z-index: 4;

                .card {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 277px;
                    height: 285px;
                    background-color: #142970;
                    padding: 32px 36px;
                    position: relative;
                    transition: 0.5s all;
                    box-shadow: 0 0 10px #7233cf;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0; right: 0; bottom: 0; left: 0;
                        z-index: -1;
                        margin: -4px; /* !importanté */
                        border-radius: inherit; /* !importanté */
                        border-radius: inherit;
                        background: linear-gradient(to left top, #4866cb12 0%, #4866cb8f 40%, #02c9fe47 100%);
                      }

                    &:hover {
                        transition: 0.5s all;
                        background: linear-gradient(to left top, #8914d0 0%/*bottom-right color*/, rgba(255, 0, 255, 0.5) 40% /*middle color*/, #02c9fe 100% /*top-left color*/); 

                        .image {
                            img {
                                display: none;
                            }
                            .hover {
                                display: block;
                            }
                        }
                    }


                    .image {
                        margin-bottom: 15px;   
                        margin-left: auto;
                        margin-right: auto;
                        
                        .hover {
                            display: none;
                        }
                    }

                    .title {
                        font-size: 28px;
                        margin-bottom: 12px;
                        text-align: center;
                    }

                }
            } 
        }

        &.buy-glint {
            display: flex;
            flex-direction: row;
            padding-top: 0px;
            .landing-tokens {
                top: -300px;
                left: -200px;
            }
        }

        /* SECTION 1-2 END */

        /* SECTION 2 START */

        &.section-1 {
            padding-top: 65px;
            z-index: 3;
            background-color: #05113b;
            width: fit-content;
            padding-bottom: 50px;   
            margin-top: 150px; 
            position: relative;
            margin: 0.2rem calc((100vw - 1130px) / 2);
            margin-top: 150px;
            margin-left: auto;
            margin-right: auto;
            height: 300px;
            
            .title {
                color: #FFFFFF;
                font-size: 48px;
                text-align: center;
            }

            .partners {
                display: flex;
                flex-direction: column;
                gap: 12px;
                flex-wrap: wrap;
                margin-top: 60px;                  
                
                .row {
                    display: flex;
                    gap: 12px;
                    justify-content: center;
                    padding-left: 30px;
                    padding-right: 30px;
                    position: relative;
                }
                
                .partner {
                    padding: 16px 20px;
                    position: relative;
                    cursor: pointer;
                    
                    img {
                        transition: 0.3s all;
                    }

                    &:hover {    
                        
                        &:before {
                            content: '';
                            position: absolute;
                            top: 0; right: 0; bottom: 0; left: 0;
                            z-index: -1;
                            margin: -1px; /* !importanté */
                            border-radius: inherit; /* !importanté */
                            border-radius: inherit;
                            background: linear-gradient(to left top, #8914d0 0%/*bottom-right color*/, rgba(255, 0, 255, 0.5) 40% /*middle color*/, #02c9fe 100% /*top-left color*/);
                          }
                    }
                }
            }
        }

        &.section-2 {            
            text-align: center;
            margin-bottom: 150px;

            .section-2-title, .section-2-subtitle {
                max-width: 780px;
                margin-left: auto;                
                margin-right: auto;
            }

            .section-2-title {                
                font-weight: bold;
                font-size: 48px;
                line-height: 68px;      
                margin-bottom: 25px;             
            }
    
            .section-2-subtitle {
                font-size: 20px;
            }

            .section-2-metrics {
                width: 1150px;
                height: 322px;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                background-color: #05113b;
                position: relative;
                margin-left: auto;
                margin-right: auto;
                margin-top: 70px;
                
                &:before {
                    content: '';
                    position: absolute;
                    top: 0; right: 0; bottom: 0; left: 0;
                    z-index: -1;
                    margin: -3px; /* !importanté */
                    border-radius: inherit; /* !importanté */                    
                    background: linear-gradient(to right, rgba(0, 0, 0, 0), #293D84, rgba(0, 0, 0, 0))
                  }

                .metric {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 20px;                
                    width: 278px;
                    height: 160px; 
                    border: 2px solid #293d8485;      
                    border-left: none;
                    border-top: none; 
                    position: relative;

                    &:nth-child(4), &:nth-child(8) {
                        border-right: none;                    
                    }

                    &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
                        border-bottom: none;                    
                    }

                    .title {
                        color: #15CEF7;
                        font-size: 16px;                        
                    }

                    .value {
                        font-size: 57px;
                        line-height: 40px;
                        color: #fff;
                    }
                }
            }
        }        
        /* SECTION 2 END */

        /* SECTION 3 START */

        &.section-3 {
            text-align: center;
            margin-bottom: 500px;
            background: #0C1A4A;
            margin: 0;
            padding-bottom: 85px;

            .section-3-upper {
                max-width: 1120px;                
                display: flex;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;

                .description {
                    max-width: 670px;
                    text-align: left;

                    .title {
                        font-weight: bold;
                        font-size: 48px;
                        line-height: 68px;      
                        margin-bottom: 25px;  
                    }

                    .subtitle {
                        font-size: 20px;
                    }
                }

                .buttons {
                    z-index: 4;
                    margin-left: 100px;
                    .connect, .trade {
                        font-size: 20px;
                        line-height: 36px;
                        text-align: center;
                        height: 36px;
                        padding: 16px 48px;     
                        cursor: pointer;           
                        width: 254px;                          
                    }
        
                    .connect {
                        background: linear-gradient(to left top, #8914d0 0%/*bottom-right color*/, rgba(255, 0, 255, 0.5) 40% /*middle color*/, #02c9fe 100% /*top-left color*/);                
                        color: #fff;  
                        margin-bottom: 24px;                      
                    }
        
                    .trade {
                        position: relative;
                        background-color: #0C1A4A;
                        border: 3px solid transparent;
                        height: 25px;
                        line-height: 25px;
                        margin-top: 3px;
                        width: 245px;
                        margin-left: 3px;
        
                        &:before {
                            content: '';
                            position: absolute;
                            top: 0; right: 0; bottom: 0; left: 0;
                            z-index: -1;
                            margin: -6px; /* !importanté */
                            border-radius: inherit; /* !importanté */
                            background: linear-gradient(to left top, #8914d0 0%/*bottom-right color*/, rgba(255, 0, 255, 0.5) 40% /*middle color*/, #02c9fe 100% /*top-left color*/);
                          }
                    }
                }

            }

            .section-3-lower {
                display: flex;
                justify-content: center;
                gap: 32px;
                margin-top: 64px;
                z-index: 4;

                .card {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 277px;
                    height: 285px;
                    background-color: #142970;
                    padding: 32px 36px;
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0; right: 0; bottom: 0; left: 0;
                        z-index: -1;
                        margin: -4px; /* !importanté */
                        border-radius: inherit; /* !importanté */
                        border-radius: inherit;
                        background: linear-gradient(to left top, #4866cb12 0%, #4866cb8f 40%, #02c9fe47 100%);
                      }

                    .image {
                        margin-bottom: 15px;                    
                    }

                    .title {
                        font-size: 28px;
                        margin-bottom: 12px;
                    }

                    .subtitle {
                        font-size: 16px;
                        width: 285px;
                    }

                }
            }            
        }

        /* SECTION 3 END */

        /* SECTION 4 START */

        &.section-4 {
            margin: 0;
            max-width: 1120px;
            display: flex;
            flex-direction: column;
            justify-content: center; 
            gap: 200px;            
            padding: 0.2rem calc((100vw - 1206px) / 2);
            padding-top: 289px;
            height: unset;
            
            .subsection {
                display: flex;
                justify-content: center;                

                .description {
                    width: 543px;

                    &.left {
                        margin-left: 86px;
                    }

                    .title {
                        font-size: 48px;
                        margin-bottom: 20px;
                        font-weight: bold;
                    }
    
                    .subtitle {
                        font-size: 20px;
                    }

                    .button {
                        position: relative;
                        height: 25px;
                        line-height: 25px;
                        margin-top: 3px;
                        width: 141px;
                        margin-left: 3px;
                        padding: 16px 48px;
                        text-align: center;
                        font-size: 20px;
                        margin-top: 48px;
                        background: linear-gradient(to left top, #8914d0 0%/*bottom-right color*/, rgba(255, 0, 255, 0.5) 40% /*middle color*/, #02c9fe 100% /*top-left color*/);
        
                    }
                }   
                
                .image {
                    position: relative;
                    width: 498px;
                    height: 393px;
                    
                    img {
                        position: absolute;
                        top: -180px;

                        &.left {
                            left: -78px;
                        }
                    }
                }
            }
        }

        /* SECTION 4 END */

        /* SECTION 5 START */

        &.section-5 {
            margin: 0;
            max-width: 1190px;
            display: flex;
            flex-direction: column;
            justify-content: center; 
            margin: 0.2rem calc((100vw - 1206px) / 2);
            margin-top: 164px;
            height: unset;
            text-align: center;
            padding: 0;
            margin-bottom: 164px;
            border-radius: 20px;

            .banner {
                height: 362px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-radius: 20px;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0; right: 0; bottom: 0; left: 0;
                    z-index: -1;
                    margin: -6px; /* !importanté */
                    border-radius: inherit; /* !importanté */
                    background: linear-gradient(to left top, #8914d0 0%/*bottom-right color*/, rgba(255, 0, 255, 0.5) 40% /*middle color*/, #02c9fe 100% /*top-left color*/);
                  }

                .title {
                    font-size: 48px;
                    font-weight: bold;    
                    margin-bottom: 20px;                
                }

                .subtitle {
                    font-size: 16px;
                }

                .subsubtitle {
                    font-size: 16px;
                    font-weight: bold;
                }

                .button {
                    position: relative;
                    background-color: #291860;
                    border: 3px solid transparent;
                    height: 25px;
                    line-height: 25px;
                    margin-top: 3px;
                    width: 172px;
                    margin-left: 3px;
                    padding: 16px 48px;
                    text-align: center;
                    font-size: 20px;
                    margin-top: 48px;
                    margin-left: auto;
                    margin-right: auto;
    
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0; right: 0; bottom: 0; left: 0;
                        z-index: -1;
                        margin: -6px; /* !importanté */
                        border-radius: inherit; /* !importanté */
                        background: linear-gradient(to left top, #8914d0 0%/*bottom-right color*/, rgba(255, 0, 255, 0.5) 40% /*middle color*/, #02c9fe 100% /*top-left color*/);
                      }
                }
            }
        }

        /* SECTION 5 END */


        &.section-6 {
            position: relative;
            margin: 0px;
            height: unset;
            margin-top: 200px;
            border-bottom: 2px solid #142970;
            border-top: 2px solid #142970;
            margin-bottom: 250px;

            .background {
                width: calc(100vw - 16px);
                height: calc(130vw - 16px);
                top: -208px;
                max-width: 1500px;
                max-height: 1500px;
                margin-left: auto;
                margin-right: auto;
            }

            .wrapper {
                display: flex;
                justify-content: center;
                z-index: 2;
                margin-bottom: 70px;

                .left {
                    width: 42%;

                    .title {
                        font-size: 48px;
                        margin-bottom: 40px;
                    }

                    .info {
                        display: flex;
                        gap: 16px;
                        width: 80%;
                        align-items: center;                        
                    }

                    .button {
                        position: relative;
                        height: 25px;
                        line-height: 25px;
                        margin-top: 3px;
                        width: 215px;
                        margin-left: 3px;
                        padding: 16px 48px;
                        text-align: center;
                        font-size: 20px;
                        margin-top: 48px;
                        background: linear-gradient(to left top, #8914d0 0%/*bottom-right color*/, rgba(255, 0, 255, 0.5) 40% /*middle color*/, #02c9fe 100% /*top-left color*/);
        
                    }
                }
            }
        }


        /* FOOTER START */        

        /* FOOTER END */
    }

    .footer {
        margin-left: auto;
        margin-right: auto;
        max-width: unset;
        display: flex;
        flex-direction: column;
        padding-top: -50px;
        padding-bottom: 100px;
        background: linear-gradient(to bottom, #05113B 17%, #8912C4 110%, #8912C4 100%);

        .groups {
            display: flex;
            justify-content: center;
            gap: 100px;
            flex-direction: row;
            margin-bottom: 122px;

            .group.image {
                width: 286px;
                display: flex;
                flex-direction: column;
                gap: 35px;
                
                .logo img{
                    width: 245px;
                    height: 49px;
                    margin-left: -16px;
                }

                .description {
                    color: #FFFFFF;
                }
            }

            .group.social {
                width: 221px;
                .title {
                    font-size: 20px;
                    color: #A589FF;
                    margin-bottom: 32px;
                }

                .description {
                    color: #FFFFFF;
                    margin-bottom: 24px;
                }

                .socials {
                    display: flex;
                    justify-content: flex-start;
                    gap: 15px;

                    a {
                        .hover {
                            display: none;
                        }

                        &:hover {

                            img {
                                display: none;
                            }

                            .hover {
                                display: block;
                            }

                        }
                    }
                }
            };

            .group:not(.group.image, .group.social) {
                width: unset;
                height: auto;                

                .title {
                    font-size: 20px;
                    color: #A589FF;
                    margin-bottom: 32px;
                }

                .links {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;                    

                    a {
                        font-size: 16px;
                        color: #FFFFFF;
                        text-decoration: none;
                        margin-bottom: 16px;

                        &:hover {
                            color: #02c9fe;
                        }
                    }
                }
            }
        }

        .footer-lower {
            font-size: 14px;
            color: #FFFFFF;
            padding-top: 32px;
            display: flex;
            justify-content: space-between;
            width: 1175px;
            height: 100px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            z-index: 4;

            &:before {
                content: '';
                position: absolute;
                top: -172px; right: 0; bottom: 0; left: -150px;
                z-index: -1;
                margin: 150px; /* !importanté */
                border-radius: inherit; /* !importanté */    
                width: inherit;                
                background: linear-gradient(to right, rgba(0, 0, 0, 0), #7141B4, rgba(0, 0, 0, 0))
              }
        }
    }


    // desktop - desktop-wide
    @media only screen and (max-width: 1366px) and (min-width: 1024px)  {
        .landing-container {
            margin: 0 32px;

            .section-1-moon {
                transition: 0.5s all;
                width: 464px;
                bottom: 323px;
            }
        }        
    }

    // tablet & mobile
    @media only screen and (max-width: 1023px)  {
        .section-1-glow, .section-1-partners-overlay {
            max-width: 100%;
        }
        .landing-container {
            margin: 0 16px;            

            .section-1-moon {
                transition: 0.5s all;
                width: 106px;
                bottom: 384px;
            }

            &.buy-glint {
                display: flex;
                flex-direction: column !important;
                padding-top: 0px;

                .landing-tokens {
                    top: -300px;
                    left: -200px;
                }

                .buy-title {
                    font-size: 32px !important;
                }

                #checkout {
                    margin-bottom: 150px;
                }
            }

            .section-1-wrapper {   
                z-index: 2; 
                height: 650px;    
                
                .section-1-title {
                    font-size: 45px;
                }

                .section-1-moonbeam {
                    z-index: 10;

                    img {
                        width: 250px;
                    }
                }

                .section-1-subtitle {
                    width: unset;
                    z-index: 10;
                }
    
                .section-1-buttons {
                    width: unset;
                    justify-content: center;
                    background-color: #05113bb8;
                    padding: 20px;
                    position: absolute;
                    bottom: 66px;
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    right: 0;
                    text-align: center;
    
                    .connect, .trade {
                        width: 100px;
                        font-size: 12px;
                        height: 40px;
                        padding: 5px;
                    }
    
                    .trade {
                        height: 28px;
                        line-height: 28px;
                    }
                }
            }   
                    
        }
        
        .section-1-2 {
            height: unset;
            margin: 0 16px;  
            
            .title {
                font-size: 24px !important;                
            }

            .section-3-lower {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 149px !important; 

                .card {
                    width: 105px !important;
                    height: 70px !important;

                    .image img {
                        width: 39px !important;
                        height: 39px !important;
                    }

                    .title {
                        font-size: 12px !important;
                    }
                }
            }
        }

        .section-1 {
            margin: 0 16px !important;
            height: 800px !important;

            .partners {
                .row {
                    flex-wrap: wrap;
                }

                .solidity {
                    img {
                        width: 90px !important;
                    }
                }

                .partner:not(.solidity) {
                    img {
                        width: 130px !important;
                        height: auto;
                    }
                }
            }
        }

        .section-4 {
            gap: 50px !important;
            padding-top: 80px !important;

            .subsection {
                flex-direction: column;
                align-items: center;
                
                &:not(:first-child) {
                    margin-top: -80px;
                }

                .description {
                    width: unset !important;

                    .subtitle {
                        text-align: center;
                    }

                    .title {
                        text-align: center;
                    }
                    .button {
                        margin-left: auto !important;
                        margin-right: auto;
                    }
                }

                .image {
                    margin-top: 30px;
                    img {
                        width: 300px;
                        margin-left: auto;
                        margin-right: auto;
                        left: 0;
                        right: 0;
                        top: -180px;
                    }
                }

                &.left {
                    margin-bottom: 510px;
                    .description {
                        margin-left: 0px !important;
                    }
                    .image {
                        width: unset;
                        height: unset;
                        img {
                            left: -146px !important;
                            top: 288px;
                        }
                    }
                }
            }
        }

        .section-6 {
            padding: 0 16px;    
            margin-top: 30px !important;        
            
            .wrapper {
                flex-direction: column;
                align-items: center;
                padding-top: 80px !important;
            margin-bottom: 0px !important;

                .right {
                    img {
                        width: 150px;
                        height: 368px;
                    }
                }

                .left {
                    width: unset !important;

                    .title {
                        font-size: 26px;
                        margin-bottom: 40px;
                        text-align: center;
                    }

                    .info {
                        display: flex;
                        gap: 16px;
                        width: unset !important;
                        align-items: center;    
                        margin-bottom: 32px;                    
                    }

                    .button {
                        position: relative;
                        height: 25px;
                        line-height: 25px;
                        margin-top: 3px;
                        width: 215px;
                        padding: 16px 48px;
                        text-align: center;
                        font-size: 15px;
                        margin-top: 48px;
                        background: linear-gradient(to left top, #8914d0 0%/*bottom-right color*/, rgba(255, 0, 255, 0.5) 40% /*middle color*/, #02c9fe 100% /*top-left color*/);
                        margin-left: auto !important;
                        margin-right: auto;
        
                    }
                }
            }
        }

        .footer {
            margin-left: -10px;
            width: 105% !important;

            .groups {
                flex-direction: column;
                align-items: center;

                * {
                    text-align: center;
                }

                .group {
                    width: unset !important;        
                    
                    .description {
                        margin: 0 16px;
                    }

                    .socials {
                        justify-content: center !important;
                    }
                }
            }

            .footer-lower {
                width: unset;
                font-size: 12px;
                margin: 0 16px;

                &:before {
                    width: 300px !important;
                }
            }
        }
    }
}